import { Invoice, Payments } from '@typings';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getIsRequestingPayment, requestInvoicePayment } from '../../../ducks';
import { compiledPaths } from '../../../paths';
import { createAbsoluteUrl } from '../../../utils/createAbsoluteUrl';
import { isDefined } from '../../../utils/is';
import Button from '../../various/Button';
import { PaymentMethods } from '../../various/PaymentMethods';

import styles from './InvoicePaymentForm.module.scss';

interface Props {
  invoiceIdentifiers: Invoice.InvoiceIdentifiers;
  paymentMethods: Payments.PaymentMethod[];
}

export const InvoicePaymentForm = ({ paymentMethods, invoiceIdentifiers }: Props) => {
  const dispatch = useDispatch();
  const isRequestingPayment = useSelector(getIsRequestingPayment);
  const [activeMethod, setActiveMethod] = React.useState<Nullable<string>>(paymentMethods[0]?.paymentMethod ?? null);
  const { t } = useTranslation(['invoices']);

  const onSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!isDefined(activeMethod)) {
        return;
      }

      dispatch(
        requestInvoicePayment({
          identifiers: invoiceIdentifiers,
          payment: {
            cancelUrl: window.location.href,
            paymentMethod: activeMethod,
            successUrl: createAbsoluteUrl(compiledPaths.INVOICE_PAYMENT_PROCESSING(invoiceIdentifiers)),
          },
        }),
      );
    },
    [activeMethod, dispatch, invoiceIdentifiers],
  );

  const submitText = React.useMemo(() => {
    const method = paymentMethods.find(item => item.paymentMethod === activeMethod);

    if (!isDefined(method)) {
      return;
    }

    return t('invoices:pay_with_method', { method: method.name });
  }, [activeMethod, paymentMethods, t]);

  return (
    <form onSubmit={onSubmit}>
      <PaymentMethods className={styles.paymentMethods} methods={paymentMethods} onChange={setActiveMethod} selected={activeMethod} />
      <Button className={styles.submitButton} type="submit" variant={['button', 'small', 'blue']} isLoading={isRequestingPayment}>
        {isRequestingPayment ? t('invoices:requesting_payment') : submitText}
      </Button>
    </form>
  );
};
