import * as amplitude from '@amplitude/analytics-browser';
import { API } from '@typings';
import { combineEpics } from 'redux-observable';
import { from, timer } from 'rxjs';
import { filter, map, mergeMap, takeWhile } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getOrderId, selectionImportRequest, selectionImportStatusRequest, selectionImportSuccess } from '../ducks';
import { isDefined } from '../utils/is';
import { isEmpty } from '../utils/isEmpty';
import { mapResponse } from '../utils/operators/mapResponse';

import { handleSelectionImportFailure } from './responseHandlers';

const POLLING_INTERVAL = 3000;
const POLLING_ATTEMPTS = 20;

const selectionImportRequestEpic: AppEpic = (action$, store$, { selectionRepository }) =>
  action$.pipe(
    filter(isActionOf(selectionImportRequest)),
    map(action => action.payload),
    mergeMap(async data => selectionRepository.importSelection(getOrderId(store$.value), data)),
    mapResponse(
      res => selectionImportStatusRequest(res.data),
      error => {
        amplitude.track('selectionImport.form.import.failure');

        return handleSelectionImportFailure(error);
      },
    ),
  );

const getShouldPoll = (response: API.SuccessResponse<Responses.SelectionImport> | API.FailedResponse<string>, index: number) => {
  const isSuccessResponse = response.status === 'SUCCESS';
  const isTimeout = index >= POLLING_ATTEMPTS;

  return (!isSuccessResponse || !response.data.completed) && !isTimeout;
};

const selectionImportStatusRequestEpic: AppEpic = (action$, store$, { selectionRepository }) =>
  action$.pipe(
    filter(isActionOf(selectionImportStatusRequest)),
    map(action => action.payload),
    mergeMap(({ jobId }) =>
      from(
        timer(0, POLLING_INTERVAL).pipe(
          mergeMap(async () => selectionRepository.getSelectionImportStatus(getOrderId(store$.value), jobId)),
          takeWhile(getShouldPoll, true),
          filter((response, index) => !getShouldPoll(response, index)),
        ),
      ).pipe(
        mapResponse(
          res => {
            const data = res.data as Responses.SelectionImportSuccess;

            if (isDefined(data.result) && !isEmpty(data.result)) {
              amplitude.track('selectionImport.form.import.warning');
            }

            amplitude.track('selectionImport.form.import.success');

            return selectionImportSuccess(data);
          },
          error => {
            amplitude.track('selectionImport.form.import.failure');

            return handleSelectionImportFailure(error);
          },
        ),
      ),
    ),
  );

export const selectionImportEpic = combineEpics(selectionImportRequestEpic, selectionImportStatusRequestEpic);
