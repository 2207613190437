import { useTranslation } from 'react-i18next';

import Heading from '../../various/Heading';
import { Wrapper, WrapperSize } from '../../various/Wrapper';

export const InvoicePaymentSuccessPage = () => {
  const { t } = useTranslation(['invoices', 'payments']);

  return (
    <Wrapper size={WrapperSize.LARGE}>
      <Heading title={t('invoices:thanks_for_payment')} />
      <p>{t('payments:payment_successful')}</p>
    </Wrapper>
  );
};
