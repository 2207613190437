import i18next from 'i18next';
import { push, replace } from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { EMPTY, from } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { addToast } from '../components/various/Toasts';
import {
  getIsLoggedIn,
  requestInvoicePayment,
  requestInvoicePaymentFailure,
  requestInvoicePaymentResultLongPolling,
  requestOrderPayment,
  requestOrderPaymentFailure,
  requestOrderPaymentResult,
  requestOrderPaymentResultLongPolling,
} from '../ducks';
import { getIsOrderClosed, getIsOrderSplit } from '../logic/Orders';
import { invoicePaymentResultLongPolling, orderPaymentResultLongPolling } from '../logic/payments';
import { compiledPaths, paths } from '../paths';
import { embedPaymentScript } from '../utils/embedPaymentScript';
import { getError } from '../utils/errors';
import { mapResponse } from '../utils/operators/mapResponse';

import { handleCheckoutFailure } from './responseHandlers/checkout';
import { handlePaymentResultSuccess } from './responseHandlers/payments';

const requestInvoicePaymentEpic: AppEpic = (action$, _, { paymentsRepository, pushEventGTM }) =>
  action$.pipe(
    filter(isActionOf(requestInvoicePayment)),
    tap(() => pushEventGTM({ event: 'stripe-payment-start' })),
    map(action => action.payload),
    mergeMap(async ({ payment, identifiers }) => paymentsRepository.getRequestInvoicePayment(payment, identifiers)),
    mapResponse(
      res => {
        embedPaymentScript(res.data.formHtml);

        return EMPTY;
      },
      error => {
        const { errors } = getError(error);
        const [errorMessage] = Object.values(errors);

        addToast(i18next.t('invoices:request_payment_fail'), { description: errorMessage });

        return requestInvoicePaymentFailure();
      },
    ),
  );

const requestInvoicePaymentResultLongPollingEpic: AppEpic = (action$, state, { pushEventGTM }) =>
  action$.pipe(
    filter(isActionOf(requestInvoicePaymentResultLongPolling)),
    map(action => action.payload),
    mergeMap(identifiers =>
      from(invoicePaymentResultLongPolling(identifiers)).pipe(
        mapResponse(
          res => {
            const isLoggedIn = getIsLoggedIn(state.value);
            const isInvoicePaid = res.data.status === 'PAID';
            const event = isInvoicePaid ? 'stripe-payment-success' : 'stripe-payment-timeout';
            const paymentSuccessPath = isLoggedIn ? compiledPaths.INVOICE_DETAILS : compiledPaths.INVOICE_PAYMENT_SUCCESS;
            const redirectPath = isInvoicePaid ? paymentSuccessPath : compiledPaths.INVOICE_PAYMENT_PENDING;

            pushEventGTM({ event });

            return [replace({ pathname: redirectPath(identifiers) })];
          },
          () => replace({ pathname: paths.PAGE_NOT_FOUND }),
        ),
      ),
    ),
  );

const requestOrderPaymentEpic: AppEpic = (action$, _, { paymentsRepository }) =>
  action$.pipe(
    filter(isActionOf(requestOrderPayment)),
    map(action => action.payload),
    mergeMap(async ({ payment, checkoutData, orderId }) =>
      paymentsRepository.getRequestOrderPayment({ ...payment, ...checkoutData }, orderId),
    ),
    mapResponse(
      res => {
        embedPaymentScript(res.data.formHtml);

        return EMPTY;
      },
      error => [requestOrderPaymentFailure(), handleCheckoutFailure(error)],
    ),
  );

const requestOrderPaymentResultEpic: AppEpic = (action$, _, { selectionRepository }) =>
  action$.pipe(
    filter(isActionOf(requestOrderPaymentResult)),
    map(action => action.payload),
    mergeMap(orderId =>
      from(selectionRepository.loadOrder(orderId)).pipe(
        mapResponse(
          res => {
            if (getIsOrderSplit(res.data) || getIsOrderClosed(res.data.order)) {
              return handlePaymentResultSuccess(res, orderId);
            }

            return EMPTY;
          },
          () => replace({ pathname: paths.PAGE_NOT_FOUND }),
        ),
      ),
    ),
  );

const requestOrderPaymentResultLongPollingEpic: AppEpic = action$ =>
  action$.pipe(
    filter(isActionOf(requestOrderPaymentResultLongPolling)),
    map(action => action.payload),
    mergeMap(orderId =>
      from(orderPaymentResultLongPolling(orderId)).pipe(
        mapResponse(
          res => {
            if (getIsOrderSplit(res.data) || getIsOrderClosed(res.data.order)) {
              return handlePaymentResultSuccess(res, orderId);
            }

            return push({ pathname: compiledPaths.ORDER_PAYMENT_PENDING({ id: orderId }) });
          },
          () => replace({ pathname: paths.PAGE_NOT_FOUND }),
        ),
      ),
    ),
  );

export const paymentsEpic = combineEpics(
  requestInvoicePaymentEpic,
  requestInvoicePaymentResultLongPollingEpic,
  requestOrderPaymentEpic,
  requestOrderPaymentResultEpic,
  requestOrderPaymentResultLongPollingEpic,
);
