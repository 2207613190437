import * as Sentry from '@sentry/react';
import { pipe } from 'ramda';

import { isDefined } from '../is';
import { isEmpty } from '../isEmpty';

const getIsLoggableOrigin = (url: string) => {
  const urlOrigin = new URL(url, window.location.origin).origin;

  const windowOrigin = window.location.origin;
  const apiOrigin = new URL(window.__SHOWROOM_SETTINGS__.API).origin;

  return urlOrigin === windowOrigin || urlOrigin === apiOrigin;
};

const obscureUrl = (urlToObscure: string) => {
  const url = new URL(urlToObscure, window.location.origin);
  const keysToObscure = ['search'];
  keysToObscure.forEach(key => {
    if (url.searchParams.has(key)) {
      url.searchParams.set(key, '********');
    }
  });

  return url.toString();
};

const obscureNavigationBreadcrumb = (breadcrumb: Sentry.Breadcrumb) => {
  if (!isDefined(breadcrumb.data)) {
    return breadcrumb;
  }

  if (breadcrumb.category === 'navigation') {
    // eslint-disable-next-line functional/immutable-data
    breadcrumb.data.from = obscureUrl(breadcrumb.data.from);
    // eslint-disable-next-line functional/immutable-data
    breadcrumb.data.to = obscureUrl(breadcrumb.data.to);
  }

  return breadcrumb;
};

const obscureXhrBreadcrumb = (breadcrumb: Sentry.Breadcrumb) => {
  if (!isDefined(breadcrumb.data)) {
    return breadcrumb;
  }

  if (breadcrumb.category === 'xhr') {
    if (!getIsLoggableOrigin(breadcrumb.data.url)) {
      return null;
    }

    const obscuredUrl = obscureUrl(breadcrumb.data.url);
    const obscuredUrlInOriginalFormat = obscuredUrl.replace(location.origin, '');

    // eslint-disable-next-line functional/immutable-data
    breadcrumb.data.url = obscuredUrlInOriginalFormat;
  }

  return breadcrumb;
};

const obscureSentryEventUrl = (event: Sentry.Event) => {
  if (event.request?.url) {
    const obscuredUrl = obscureUrl(event.request.url);
    // eslint-disable-next-line functional/immutable-data
    event.request.url = obscuredUrl;
  }

  return event;
};

const obscureReplayEvent = (event: Sentry.ReplayFrameEvent) => {
  if (event.data.tag !== 'performanceSpan') {
    return event;
  }

  const { payload } = event.data;

  if (payload.op.startsWith('navigation')) {
    // eslint-disable-next-line functional/immutable-data
    payload.description = obscureUrl(payload.description);
  }

  if (payload.op.startsWith('resource') && !getIsLoggableOrigin(payload.description)) {
    return null;
  }

  return event;
};

const getClientEnvironmentName = (api: string) => {
  const apiHost = new URL(api).host;

  return apiHost.split('.')[0];
};

export const initSentry = () => {
  const {
    API,
    SENTRY_DSN,
    SENTRY_SAMPLE_RATE,
    SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    SENTRY_REPLAYS_ERROR_SAMPLE_RATE,
    SENTRY_REPLAYS_ENABLED,
  } = window.__SHOWROOM_SETTINGS__;

  const hasRequiredEnvironmentVariables = isDefined(SENTRY_DSN) && !isEmpty(SENTRY_DSN) && isDefined(SENTRY_SAMPLE_RATE);
  const clientEnvironmentName = getClientEnvironmentName(API);

  if (!hasRequiredEnvironmentVariables || !isDefined(clientEnvironmentName)) {
    return;
  }

  const shouldEnableReplays = SENTRY_REPLAYS_ENABLED === 'true';

  Sentry.setUser({ username: clientEnvironmentName });
  Sentry.setTag('company_name', clientEnvironmentName);
  Sentry.init({
    beforeBreadcrumb(breadcrumb) {
      return pipe(obscureNavigationBreadcrumb, obscureXhrBreadcrumb)(breadcrumb);
    },
    beforeSend(event) {
      return obscureSentryEventUrl(event);
    },
    dsn: SENTRY_DSN,
    environment: window.__SHOWROOM_SETTINGS__.RELEASE_STAGE,
    ignoreErrors: [/ResizeObserver loop/, /getRestrictions is not a function/],
    release: window.__SHOWROOM_SETTINGS__.APP_VERSION,
    ...(shouldEnableReplays && {
      integrations: [
        Sentry.replayIntegration({
          beforeAddRecordingEvent(event) {
            return obscureReplayEvent(event);
          },
          blockAllMedia: false,
          mask: ['[data-sentry-mask]'],
          maskAllText: false,
          minReplayDuration: 10000,
        }),
      ],
      replaysOnErrorSampleRate: isDefined(SENTRY_REPLAYS_ERROR_SAMPLE_RATE) ? parseFloat(SENTRY_REPLAYS_ERROR_SAMPLE_RATE) : 0,
      replaysSessionSampleRate: isDefined(SENTRY_REPLAYS_SESSION_SAMPLE_RATE) ? parseFloat(SENTRY_REPLAYS_SESSION_SAMPLE_RATE) : 0,
    }),
    sampleRate: parseFloat(SENTRY_SAMPLE_RATE),
    sendDefaultPii: false,
  });
};

export const renderSentryMaskedText = (value: string) => {
  return <span data-sentry-mask>{value}</span>;
};
